<template>
	<div class="student-place-qcm-container">
		<FullPageLoader v-if="isLoading"/>
		
		
		<table v-if="!isLoading">
			<tr>
				<th>Etudiant</th>
				<th>Note</th>
			</tr>
			<tr v-for="student in students" :key="student.id">
				<td>{{ student.name }}</td>
				<td>{{ getNote(student.id) }}</td>
			</tr>
		</table>
		
		
	</div>
</template>

<script>
import { getQCMService } from '../../../../../services/qcmServices'
import { filteredQCMStudentNotesService } from '../../../../../services/qcmStudentNotesServices'
import { allStudentsInClasseService } from '../../../../../services/studentsService'
import FullPageLoader from '../../../../Utils/FullPageLoader.vue'
export default {
	components: {
		FullPageLoader,
	},
	data() {
		return {
			notes:[],
			qcm: {},
			students: [],
			isLoading: false,
			maxNote: 0
		}
	},

	methods: {
		getNote(studentId){
			const note = this.notes.find(note => note.student === studentId)
			if (!note) {
				return 'QCM non répondu'
			}
			return `${note.note} / ${this.maxNote}`
		},
		async getQcm(){
			this.qcm = await getQCMService(this.$route.params.id)
			for (const question of this.qcm.questions) {
				this.maxNote += question.point
			}
		},
		async getQcmNotes(){
			const filter = `qcm=${this.$route.params.id}`
			this.notes = await filteredQCMStudentNotesService(filter)
		},
		async getStudents(){
			const res = await allStudentsInClasseService(this.qcm.classroom)
			this.students = res
		}
		
	},
	async created() {
		this.isLoading = true
		await this.getQcm()
		await this.getQcmNotes()
		await this.getStudents()
		this.isLoading = false
	},
	
}
</script>

<style scoped lang="scss">
@import '../../../../../assets/scss/student-place/qcm.scss';

.input-container{
	width: 60%;
	margin-inline: auto;
	margin-top: 4rem;
}
table{
	width: 60%;
	margin-inline: auto;
	background-color: #fefefe;

	th{
		text-align: left;
	}
}
</style>
